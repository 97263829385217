export default function flattenArrays(data) {
    function flatten(obj, prefix = '') {
        return Object.keys(obj).reduce((acc, key) => {
            const value = obj[key];
            const newKey = prefix ? `${prefix}.${key}` : key;
            if (Array.isArray(value)) {
                acc[newKey] = value.join(', ');
            } else if (typeof value === 'object' && value !== null) {
                acc = { ...acc, ...flatten(value, newKey) };
            } else {
                acc[newKey] = value;
            }
            return acc;
        }, {});
    }

    return data.map(item => flatten(item));
}
